export const globalRoutes = [{
	path: '/',
	name: 'Login',
	component: () => import('../views/Login.vue')
},
{
	path: '/logout',
	name: 'Logout',
	component: () => import('../views/Logout.vue')
},
{
	path: '/passwd-reset/:id',
	name: 'Reset',
	component: () => import('../views/PasswordReset.vue')
},
{
	path: '/new-user/:id',
	name: 'New User',
	component: () => import('../views/Recruitment.vue')
},
{
	path: '/new-course-user/:id',
	name: 'New Course User',
	component: () => import('../views/CourseRecruitment.vue')
}
]
