export const modRoutes = [
	{
		path: '/moderator/clients',
		name: 'ClientsModerator',
		component: () => import('../views/moderator/Clients.vue'),
		meta: {
			role: 'moderator',
            title: 'Klienci'
		}
	},
    {
		path: '/moderator/client/:id',
		name: 'ClientViewModerator',
		component: () => import('../views/administrator/Client.vue'),
		meta: {
			role: 'moderator'
		}
	},
	{
		path: '/moderator/apprentice-tasks',
		name: 'ApprenticeTasksModerator',
		component: () => import('../views/ApprenticeTasks.vue'),
		meta: {
			role: 'moderator',
            title: 'Zadania - kurs copywritingu'
		}
	},
	{
		path: '/moderator/apprentice',
		name: 'ApprenticeAdminModerator',
		component: () => import('../views/Apprentice.vue'),
		meta: {
			role: 'moderator',
            title: 'Kursanci copywritingu'
		}
	},
	{
		path: '/moderator/apprentice/:id',
		name: 'SingleApprenticeModerator',
		component: () => import('../views/SingleApprentice.vue'),
		meta: {
			role: 'moderator'
		}
	},
	{
		path: '/moderator/apprentice/:id/task/:idtask',
		name: 'SingleApprenticeTask',
		component: () => import('../views/SingleApprenticeTask.vue'),
		meta: {
			role: 'moderator'
		}
	},
	{
		path: '/moderator/documents',
		name: 'DocumentModerator',
		component: () => import('../views/moderator/Documents.vue'),
		meta: {
			role: 'moderator',
            title: 'Kontakt'
		}
	},
	{
		path: '/moderator/employees',
		name: 'EmployeesModerator',
		component: () => import('../views/moderator/Employees.vue'),
		meta: {
			role: 'moderator',
            title: 'Pracownicy'
		}
	},
	{
		path: '/moderator/task/:id',
		name: 'SingleTaskModerator',
		component: () => import('../views/SingleTask.vue')
		,
		meta: {
			role: 'moderator'
		}
	},
	{
		path: '/moderator/payments/:month',
		name: 'PaymentsModerator',
		component: () => import('../views/moderator/Payments.vue'),
		meta: {
			role: 'moderator',
            title: 'Płatności'
		}
	},
	{
		path: '/moderator/my-account',
		name: 'MyAccountModerator',
		component: () => import('../views/MyAccount.vue'),
		meta: {
			role: 'moderator'
		}
	},
    {
		path: '/moderator/course-tasks',
		name: 'CourseTasksModerator',
		component: () => import('../views/CourseTasks.vue'),
		meta: {
			role: 'moderator',
            title: 'Zadania - kurs korekty'
		}
	},
	{
		path: '/moderator/course',
		name: 'AllCourseViewModerator',
		component: () => import('../views/Course.vue'),
		meta: {
			role: 'moderator',
            title: 'Kursanci korekty'
		}
	},
	{
		path: '/moderator/course/:id',
		name: 'SingleCourseModerator',
		component: () => import('../views/SingleCourse.vue'),
		meta: {
			role: 'moderator'
		}
	},
	{
		path: '/moderator/course/:id/task/:idtask',
		name: 'SingleCourseTaskModerator',
		component: () => import('../views/SingleCourseTask.vue'),
		meta: {
			role: 'moderator'
		}
	},
	{
		path: '/moderator/:month',
		name: 'HomeModerator',
		component: () => import('../views/moderator/'),
		meta: {
			role: 'moderator',
            title: 'Zadania'
		}
	},
]