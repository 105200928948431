import Vue from 'vue'
import VueRouter from 'vue-router'

import { globalRoutes } from './globalRoutes';
import { adminRoutes } from './adminRoutes';
import { modRoutes } from './modRoutes';
import { copyRoutes } from './copyRoutes';
import { korektorRoutes } from './korektorRoutes';
import { apprenticeRoutes } from './apprenticeRoutes';
import { courseRoutes } from './courseRoutes';


Vue.use(VueRouter)

const routes = [
	...globalRoutes,
	...adminRoutes,
	...modRoutes,
	...copyRoutes,
	...korektorRoutes,
	...apprenticeRoutes,
    ...courseRoutes,
];


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const role = window.localStorage.getItem('role');
	const user = window.localStorage.getItem('user');
	let date = new Date().toISOString().substr(0, 7);

    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = "Content Writer";
    }

	if ((to.name == 'Reset' || to.name == 'New User' || to.name == 'New Course User') && !user){
		next()
	}
	else if (to.name !== 'Login' && !user) {
		next({ name: 'Login' })
	} else if (!user) {
		next()
	}else {
		if (to.meta.role == role) {
			next()
		} else {
			if(role == 'praktykant'){
				next(`/${role}/`);
			}else if(role == 'kursant'){
				next(`/${role}/`);
			}else{
				next(`/${role}/${date}`)
			}
		}
	}
})

export default router
