import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#37B14C',
                secondary: '#17223A',
                accent: '#9be0a6',
                error: '#F00',
                indigo: '#37B14C',
            },
            dark: {
                primary: '#37B14C',
                secondary: '#17223A',
                accent: '#9be0a6',
                error: '#F00',
                indigo: '#37B14C',
            },
        },
    },
});