export const korektorRoutes = [
	{
		path: '/korektor/my-account',
		name: 'MyAccountKorektor',
		component: () => import('../views/MyAccount.vue'),
		meta: {
			role: 'korektor'
		}
	},
    // Apprentice
	{
		path: '/korektor/apprentice',
		name: 'ApprenticeAdmin',
		component: () => import('../views/Apprentice.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/apprentice/:id',
		name: 'ApprenticeAdmin',
		component: () => import('../views/SingleApprentice.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/apprentice/:id/task/:idtask',
		name: 'SingleApprenticeTask',
		component: () => import('../views/SingleApprenticeTask.vue'),
		meta: {
			role: 'korektor'
		}
	},
    // Course
    {
		path: '/korektor/course',
		name: 'CourseAdmin',
		component: () => import('../views/Course.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/course/:id',
		name: 'CourseAdmin',
		component: () => import('../views/SingleCourse.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/course/:id/task/:idtask',
		name: 'SingleCourseTask',
		component: () => import('../views/SingleCourseTask.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/payments/:month/:id',
		name: 'PaymentsKorektor',
		component: () => import('../views/korektor/Payments.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/task/:id',
		name: 'SingleTaskKorektor',
		component: () => import('../views/SingleTask.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/documents',
		name: 'DocumentsKorektor',
		component: () => import('../views/korektor/Documents.vue'),
		meta: {
			role: 'korektor'
		}
	},
	{
		path: '/korektor/:month',
		name: 'HomeKorektor',
		component: () => import('../views/korektor/'),
		meta: {
			role: 'korektor'
		}
	},

]