export const copyRoutes = [
    {
        path: "/copywriter/my-account",
        name: "MyAccountCopywriter",
        component: () => import("../views/MyAccount.vue"),
        meta: {
            role: "copywriter",
        },
    },
    {
        path: "/copywriter/payments/:month/:id",
        name: "PaymentsCopywriter",
        component: () => import("../views/copywriter/Payments.vue"),
        meta: {
            role: "copywriter",
        },
    },
    {
        path: "/copywriter/task/:id",
        name: "SingleTaskCopywriter",
        component: () => import("../views/SingleTask.vue"),
        meta: {
            role: "copywriter",
        },
    },
    {
        path: "/copywriter/take-task",
        name: "TakeTaskCopywriter",
        component: () => import("../views/copywriter/TakeTask.vue"),
        meta: {
            role: "copywriter",
        },
    },
    {
        path: "/copywriter/documents",
        name: "DocumentCopywriter",
        component: () => import("../views/copywriter/Documents.vue"),
        meta: {
            role: "copywriter",
        },
    },
    {
        path: "/copywriter/:month",
        name: "HomeCopywriter",
        component: () => import("../views/copywriter/"),
        meta: {
            role: "copywriter",
        },
    },
];
