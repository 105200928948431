export const adminRoutes = [ 
	{
		path: '/administrator/clients',
		name: 'ClientsAdmin',
		component: () => import('../views/administrator/Clients.vue'),
		meta: {
			role: 'administrator',
            title: 'Klienci'
		}
	},
	{
		path: '/administrator/documents',
		name: 'DocumentAdministrator',
		component: () => import('../views/administrator/Documents.vue'),
		meta: {
			role: 'administrator',
            title: 'Kontakt'
		}
	},
	{
		path: '/administrator/employees',
		name: 'EmployeesAdmin',
		component: () => import('../views/administrator/Employees.vue'),
		meta: {
			role: 'administrator',
            title: 'Pracownicy'
		}
	},
    // Apprentice
	{
		path: '/administrator/apprentice-tasks',
		name: 'ApprenticeTasksAdmin',
		component: () => import('../views/ApprenticeTasks.vue'),
		meta: {
			role: 'administrator',
            title: 'Zadania - kurs copywritingu'
		}
	},
    {
		path: '/administrator/client/:id',
		name: 'ClientViewAdmin',
		component: () => import('../views/administrator/Client.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/apprentice',
		name: 'AllApprenticeViewAdmin',
		component: () => import('../views/Apprentice.vue'),
		meta: {
			role: 'administrator',
            title: 'Kursanci copywritingu'
		}
	},
	{
		path: '/administrator/apprentice/:id',
		name: 'SingleApprenticeAdmin',
		component: () => import('../views/SingleApprentice.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/apprentice/:id/task/:idtask',
		name: 'SingleApprenticeTaskAdmin',
		component: () => import('../views/SingleApprenticeTask.vue'),
		meta: {
			role: 'administrator'
		}
	},
    // Courses
    {
		path: '/administrator/course-tasks',
		name: 'CourseTasksAdmin',
		component: () => import('../views/CourseTasks.vue'),
		meta: {
			role: 'administrator',
            title: 'Zadania - kurs korekty'
		}
	},
	{
		path: '/administrator/course',
		name: 'AllCourseViewAdmin',
		component: () => import('../views/Course.vue'),
		meta: {
			role: 'administrator',
            title: 'Kursanci korekty'
		}
	},
	{
		path: '/administrator/course/:id',
		name: 'SingleCourseAdmin',
		component: () => import('../views/SingleCourse.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/course/:id/task/:idtask',
		name: 'SingleCourseTaskAdmin',
		component: () => import('../views/SingleCourseTask.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/task/:id',
		name: 'SingleTaskAdmin',
		component: () => import('../views/SingleTask.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/payments/:month',
		name: 'PaymentsAdmin',
		component: () => import('../views/administrator/PaymentsAdmin.vue'),
		meta: {
			role: 'administrator',
            title: 'Płatności'
            
		}
	},
	{
		path: '/administrator/my-account',
		name: 'MyAccountAdmin',
		component: () => import('../views/MyAccount.vue'),
		meta: {
			role: 'administrator'
		}
	},
	// {
	// 	path: '/administrator/',
	// 	name: 'HomeAdmin',
	// 	component: () => import('../views/administrator/'),
	// 	meta: {
	// 		role: 'administrator'
	// 	}
	// },
    // Statistics
    {
		path: '/administrator/statistics',
		name: 'StatisticsAdmin',
		component: () => import('../views/administrator/Statistics'),
		meta: {
			role: 'administrator',
            title: 'Statystyki'
		}
	},
	{
		path: '/administrator/:month',
		name: 'HomeAdmin',
		component: () => import('../views/administrator'),
		meta: {
			role: 'administrator',
            title: 'Zadania'
		}
	},
    
    
]