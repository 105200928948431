export const courseRoutes = [
	{
		path: '/kursant/my-account',
		name: 'MyAccountCourse',
		component: () => import('../views/MyAccount.vue'),
		meta: {
			role: 'kursant'
		}
	},
    {
		path: '/kursant/documents',
		name: 'DocumentsCourse',
		component: () => import('../views/kursant/Documents.vue'),
		meta: {
			role: 'kursant'
		}
	},

	{
		path: '/kursant/',
		name: 'HomeCourse',
		component: () => import('../views/kursant/'),
		meta: {
			role: 'kursant'
		}
	},
	{
		path: '/kursant/:id/task/:idtask',
		name: 'SingleTask',
		component: () => import('../views/SingleCourseTask.vue'),
		meta: {
			role: 'kursant'
		}
	},
]